import React from 'react'

export default function UserInfo({username,lastmessage,messages,unread_messages,onClick}) {

  const setCurrentChat = (chatID,messages) =>{
    onClick(chatID,messages)
  }

  return (
    <div onClick={()=>{setCurrentChat(username,messages)}} className='flex justify-start space-x-5 relative p-3 cursor-pointer content-center border-b border-b-gray-300 hover:bg-[#f0f2f5] h-20 w-full'>
        <div className='flex place-content-center max-w-[84px] w-full h-full'>
            <img className='rounded-full w-full h-full' src="https://xsgames.co/randomusers/avatar.php?g=pixel" alt="https://xsgames.co/randomusers/avatar.php?g=pixel" />
        </div>

        <div className='w-4/5 h-full'>
            <h3 className='font-semibold text-sm md:text-base'>{username}</h3>
            <p className='text-gray-400 truncate whitespace-nowrap font-normal text-xs md:text-sm'>{lastmessage}</p>
        </div>

        { unread_messages !== 0 && <div className='bg-green-500 text-white rounded-full font-medium text-xs p-4 w-3 h-3 flex place-content-center place-items-center md:text-sm'>{unread_messages}</div>}
    </div>
  )
}

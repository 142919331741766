import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthPost } from '../APICalls/PostAPIMethod';
import { VeloxBarLoader } from './Loader/VeloxLoader';

export default function AuthPage() {
    const navigateTo = useNavigate();
    const [email,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [togglePassword,setTogglePassword] = useState(false);
    const [request,setRequests] = useState({loading:false,data:null,error:false})
  
    const handleSubmit =(e)=>{
        e.preventDefault()

        const formData = JSON.stringify({email,password});
        AuthPost('chat/authenticate/',formData,setRequests);
    }

    useEffect(()=>{
        if (request.data?.token) {
            navigateTo("/chat")
        }
    },[request.data,navigateTo])


  return (
    <div className='w-full h-screen flex flex-col place-items-center p-5'>
      {request.loading && <VeloxBarLoader/>}
      <div className='max-w-xl max-h-[480px] h-full w-full mx-auto my-20 bg-[#323b4c4f] p-5 rounded-lg'>
            <img className='h-20 w-20 mx-auto' src="https://velox.com.ng/static/Images/logo%20black.svg" alt="" />

            <h3 className='text-white font-medium text-center text-sm md:text-base'>Enter you velox Account password to continue to support</h3>
            <form onSubmit={handleSubmit} className="w-full mt-5">
                {request.error && <p className='font-medium text-center text-sm text-red-600'>{request.data?.error}</p>}
                <div className='flex flex-col space-y-4 md:space-y-5'>

                    <div className='relative w-full '>
                        <label htmlFor="email" className='text-[#1A1A1A] text-sm  leading-5 font-normal md:text-base'>Email address</label>
                        <input onChange={(e)=>{setUsername(e.target.value)}} type="email" name='email' required placeholder='example@email.com' className='mt-2 text-sm border border-[#828282] outline-none focus:border-[#fdae5c] hover:border-[#fdae5c] rounded-xl w-full h-[55px] px-6 py-[18px] md:text-base md:rounded-2xl md:h-[65px]' />
                    </div>

                    <div className='relative w-full'>
                        <label htmlFor="password" className='text-[#1A1A1A] text-sm leading-5 font-normal md:text-base'>Password</label>
                        <input onChange={(e)=>{setPassword(e.target.value)}} type={`${togglePassword? 'text' : 'password'}`} required name='password' placeholder='Enter your velox password' className='mt-2 text-sm border border-[#828282] outline-none focus:border-[#fdae5c] hover:border-[#fdae5c] rounded-xl w-full h-[55px] px-6 py-[18px] md:text-base md:rounded-2xl md:h-[65px]' />

                        <span className='absolute cursor-pointer bottom-[10px] top-[33px] w-6 right-0 self-center mx-[27px] h-[55px] md:h-[65px]' onClick={()=>{setTogglePassword(!togglePassword)}}>
                            {togglePassword ?
                                <svg 
                                className="w-full h-full" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24" 
                                xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            :
                                <svg 
                                    className="w-full h-full" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24" 
                                    xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                </svg>
                            }
                        </span>
                    </div>

                    <div className='h-[55px] md:h-[77px]'>
                        <button type='submit' className='bg-[#E0E0E0] hover:bg-[#E8730C] rounded-xl text-white font-medium text-base leading-9 h-full w-full md:rounded-2xl md:text-[28px]'>Authenticate</button>
                    </div>
                </div>
            </form>
      </div>
    </div>
  )
}

import React,{useContext,useEffect} from 'react';
import UserInfo from './UserInfo';
import { ChatContext } from '../Context/ChatContext';
import GetAPIMethods from '../../APICalls/GetAPIMethod';
import FakeUserListLoader from '../Loader/FakeUserListLoader';

export default function UsersList() {
  const {chatData,setChatData} = useContext(ChatContext);

  const userList = GetAPIMethods("chat/get-rooms/");

  const enterChat = (chatUser,messages)=>{
    setChatData({...chatData,"currentUser":chatUser,"messages":messages});
  }

  useEffect(()=>{
    if (userList.data) {
      setChatData(chatData=>({...chatData,"userList":userList.data,}));
    }

    
  },[userList.data,setChatData])
  

  return (
    <div className='w-full h-full bg-white py-5 overflow-y-scroll lmax-h-[710px] mt-16 lg:max-w-sm'>
      {userList.loading && <FakeUserListLoader/>}

      {
        !userList.loading & !userList.error && chatData?.userList?.map((user,id)=>{
          return(<UserInfo key={id} username={user?.name} messages={user?.messages} lastmessage={user?.last_message?.content} unread_messages={user?.unread_messages} onClick={enterChat}/>)
        })
      }  
    </div>
  )
}

import React,{useContext,useState,useEffect,useRef,useCallback} from 'react';
import UserJoinedChat from './UserJoinedChat';
import ChatEventMessage from './ChatEventMessage';
import UserMessageBubble from './UserMessageBubble';
import { ChatContext } from '../Context/ChatContext';
import { VeloxBarLoader } from '../Loader/VeloxLoader';
import { WEB_SOCKET_URL } from '../../APICalls/AxiosBase';
// import ChatNotification from './ChatNotification';

export default function ChatBox() {
    const chatSocket = useRef(null);
    const ME = localStorage.getItem('me');
    const [chatEvent,setChatEvent] = useState([]);
    const {chatData,setChatData} = useContext(ChatContext);
    const [connecting,setConnecting] = useState({loading:false,connected:false,error:false})


    const connectWebsocket = useCallback((currentUser)=>{
        const chatLog = document.getElementById("chatLog");
        setConnecting({loading:true,connected:false,error:false})
        chatSocket.current = new WebSocket(`${WEB_SOCKET_URL}ws/chat/${currentUser}/?token=${localStorage.getItem('access_token')}`);
    
        chatSocket.current.onopen = function(e) {
            console.log("Successfully connected to the WebSocket.");
            setConnecting({loading:false,connected:true,error:false})
        }
    
        chatSocket.current.onclose = function(e) {
            setConnecting({loading:true,connected:false,error:true})
            setChatEvent((chatEvent)=>[...chatEvent,<ChatEventMessage message={"connection closed unexpectedly, retrying to reconnect"} error={true}/>]);
            console.log("WebSocket connection closed unexpectedly. Trying to reconnect in 2s...");
            setTimeout(function() {
                chatSocket.current = null
                console.log("Reconnecting...");
                setChatEvent([]);
                connectWebsocket(currentUser);
            }, 2000);
        };

        chatSocket.current.onerror = function(err) {
            setConnecting({loading:false,connected:false,error:true})
            console.log("WebSocket encountered an error: " + err.message);
            console.log("Closing the socket.");
            chatSocket.current.close();
            setTimeout(function() {
                chatSocket.current = null
                console.log("Reconnecting...");
                setChatEvent([]);
                setChatEvent((chatEvent)=>[...chatEvent,<ChatEventMessage message={"encountered an unexpected error, retrying to reconnect"} error={true}/>]);
                connectWebsocket(currentUser);
            }, 2000);
        }

        chatSocket.current.onmessage = function(e) {
            const data = JSON.parse(e.data);
            switch (data.type) {
                case "chat_message":
                    setChatEvent((chatEvent)=>[...chatEvent,<UserMessageBubble sender={ME === data.user} message={data?.message} datetime={data?.datetime}/>]);
                    break;
                case "previous_msg":
                    setChatEvent((chatEvent)=>[...chatEvent,...processPrevMSG(data.msgs)]);
                    break;
                
                case "new_messages":
                    console.log("new_messages........")
                    setChatData({...chatData,"userList":data.userList,});
                    break;
                case "user_join":
                    setChatEvent((chatEvent)=>[...chatEvent,<UserJoinedChat username={data.user}/>]);
                    break;
                case "user_leave":
                    setChatEvent((chatEvent)=>[...chatEvent,<UserJoinedChat username={data.user} joined={false}/>]);
                    break;
                default:
                    console.error("Unknown message type!");
                    break;
            }

            chatLog.scrollTop = chatLog.scrollHeight;
        }
    },[chatData?.currentUser,]);

    

    useEffect(()=>{
        setChatEvent([]);
        connectWebsocket(chatData?.currentUser)
    },[chatData?.currentUser,])


    const processPrevMSG = (msgs)=>{
        const msg = msgs?.messages?.map((msg,id)=>{
        return(<UserMessageBubble key={id} sender={ME === msg.user} message={msg?.content} datetime={msg?.datetime}/>)
        })
        return(msg)
    }
    
    const sendMessage = (e)=>{
        e.preventDefault();
        const chatMessageInput = document.getElementById("chatMessageInput");
        // ChatNotification(chatMessageInput.value,"me");
        chatSocket.current.send(JSON.stringify({
            "message": chatMessageInput.value,
        }));
        chatMessageInput.value = "";
    }

  return (
    <div className="w-full h-full bg-image relative overflow-hidden block">
        {connecting.loading && <VeloxBarLoader/>}
        <div id='chatLog' className='w-full h-full py-24 px-5 overflow-y-scroll max-h-fit'>
          {
            chatData?.messages?.map((msg,id)=>{
              return(<UserMessageBubble key={id} sender={ME === msg.user} message={msg?.content} datetime={msg?.datetime}/>)
            })
          }
          {
            chatEvent.map((event,id)=>{
                return(<div className='w-full' key={id}>{event}</div>)
            })
          }
        </div>

        <form onSubmit={sendMessage} className='w-full h-16 bg-[#f0f2f5] p-1 absolute bottom-0 flex flex-nowrap'>
            <input id='chatMessageInput' required type="text" placeholder='Type a message'  className='h-full w-4/5 mx-auto rounded-2xl p-3 outline-none text-sm font-normal text-gray-700'/>
            <button className='h-full w-10'>
                <svg id='chatMessageSend' className='w-full h-full' fill="#64737C" stroke="#f0f2f5" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                </svg>
            </button>
        </form>
    </div>
  )
}

import React from 'react'

export default function EmptyChatBox() {
  return (
    <div className='w-full h-full bg-[#F3F1EB] place-items-center hidden lg:flex'>
        <div className='text-center p-10 md:text-left'>
            <h2 className='font-bold text-3xl text-[#fd7b45]'>Select A message</h2>
            <p className='font-medium text-base text-gray-600'>Choose from your existing conversations,start a new one, or just keep swimming.</p>
        </div>
    </div>
  )
}

import './fakeuserloader.css';

import React from 'react'

export default function FakeUserListLoader() {

    const rows = [];
    for (let i = 0; i < 10; i++) {
        rows.push(<span key={i} className="loader"></span> );
    }
    return(
        <>
        {rows}
        </>
    )
}



import React,{useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../Context/ChatContext';

export default function ChatHeader() {
    const navigateTo = useNavigate();
    const {chatData,setChatData} = useContext(ChatContext);

    const goBack =()=>{
      setChatData({...chatData,"currentUser":null});
    }

    const logOut =()=>{
      localStorage.clear();
      navigateTo("/auth")
    }


  return (
    <div className='w-full flex justify-start h-20 absolute z-10 top-0 bg-[#f0f2f5]'>
        <div onClick={()=>{goBack()}} className=' max-w-xs w-full h-full border-r border-r-gray-400 lg:max-w-sm'>
            <img className='h-full w-16 mx-auto lg:w-20' src="https://velox.com.ng/static/Images/logo%20black.svg" alt="" />
        </div>

        <div className='w-full h-full flex place-items-center place-content-center px-2'>
            <h2 className='font-bold text-base lg:text-lg text-center'>{chatData?.currentUser}</h2>
        </div>

        <div className='w-full h-full flex place-items-center place-content-end px-2'>
          <h4 onClick={()=>{logOut()}} className='font-semibold text-sm md:text-base p-3 cursor-pointer bg-red-500 rounded-xl'>LogOut</h4>
        </div>
    </div>
  )
}

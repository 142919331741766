import React,{useState,useEffect} from 'react';
import ChatBox from './ChatComponents/ChatBox';
import UsersList from './ChatComponents/UsersList';
import ChatHeader from './ChatComponents/ChatHeader';
import { ChatContext } from './Context/ChatContext';
import EmptyChatBox from './ChatComponents/EmptyChatBox';


export default function Chat() {
    const [chatData,setChatData] = useState({});
    const [finalData,setFinalData] = useState([]);
    let isAdmin = localStorage.getItem('is_admin');

    useEffect(()=>{
        let ME = localStorage.getItem('me');
        if (isAdmin !== "true") {
            setChatData(chatData=>({...chatData,"currentUser":ME}));
        }
    },[isAdmin])

    return (
        <div className='absolute  w-full h-full xl:h-[90%]'>
            <ChatContext.Provider value={{
                chatData,
                setChatData,
                finalData,
                setFinalData
            }}>
                <div className='bg-[#F3F1EB] flex flex-col justify-start w-full h-full max-w-7xl relative mx-auto xl:my-5'>
                    <ChatHeader/>
                    <div className='flex w-full h-full flex-col lg:flex-row'>
                        {
                            isAdmin === 'true'?
                            <>
                                <div className={`hidden w-full h-full lg:max-w-sm lg:block`}><UsersList/></div>
                                <div className={`${chatData?.currentUser? 'hidden':'block'} w-full h-full lg:max-w-sm lg:hidden`}>
                                    <UsersList/>
                                </div>
                            </>

                            :
                            <></>
                        }
                        
                        {
                            chatData?.currentUser?
                                <ChatBox/>
                            :
                                <EmptyChatBox/>
                        }
                        
                        
                    </div>
                </div>
            </ChatContext.Provider>

            
        </div>
    )
    }

import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Chat from './Components/Chat';
import AuthPage from './Components/AuthPage';
import ProtectedRoutes from './ProtectedRoutes/ProtectedRoutes';

function App() {

  return (
    <div>

      <Router>
        <Routes>
        <Route exact path='' element={<AuthPage/>}/>
          <Route element={<ProtectedRoutes/>} >
            <Route exact path='/chat' element={<Chat/>}/>
          </Route>
          <Route exact path='/auth' element={<AuthPage/>} />
        </Routes>


      </Router>
    </div>
  );
}

export default App;

import {useState,useEffect} from "react";
import axiosInstance from "./AxiosBase";



export default function PostAPIMethods(QUERY_URL,DATA_PARAMS) {
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    useEffect(() => {
        setRequests({loading:true,data:null,error:false})

        const Header = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`
                
            }}
        
        axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
            
            response => {
                setRequests({loading:false,data:response.data,error:false})
            }
            ).catch(() =>{
            setRequests({loading:false,data:null,error:true})
            })

    },[DATA_PARAMS,QUERY_URL]);
    

    return (request);
}

export function AuthPost(QUERY_URL,DATA_PARAMS,setRequests) {
    // This handles user login
    setRequests({loading:true,data:null,error:false})
    const Header = {headers: {'Content-Type': 'application/json',Authorization:null}}//,'withCredentials':false}}
    axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
        response => {
            localStorage.setItem('isAuthenticated',true)
            localStorage.setItem('me',response.data['user'])
            localStorage.setItem('is_admin',response.data["is_admin"])
            localStorage.setItem('access_token',response.data['token'])
            axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data['token']}`;
            setRequests({loading:false,data:response?.data,error:false})
        }
        ).catch((error) =>{
            setRequests({loading:false,data:error.response?.data,error:true})
        })
}

export function PostAPIMethodStateless(QUERY_URL,DATA_PARAMS,setRequests) {
    
    setRequests({loading:true,data:null,error:false})

    const Header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`
            
        }}
    
    axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
        
        response => {
            setRequests({loading:false,data:response?.data,error:false})
        }
        ).catch((error) =>{
        setRequests({loading:false,data:error.response?.data,error:true})
        })

    
}
import React from 'react'

export default function UserJoinedChat({username,joined=true}) {

  return (
    <div className='w-full text-center my-2'>
        <h2 className={`bg-white ${joined? 'text-[#616260]':'text-red-500'} text-sm font-medium p-2 mx-auto w-fit rounded-3xl`}>{username} { joined? 'joined':'left'} the chat</h2>
    </div>
  )
}
